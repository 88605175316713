<template>
  <div class="container" v-if="detail" >
    <breadcrumb :current="{ title: detail.storeInfo.keyword }"></breadcrumb>
    <main-info :title="detail.storeInfo.keyword ">
      <img slot="img" style="height: 300px;object-fit: contain;background-color: #fff;" :src="detail.storeInfo.image" alt="">
      <div slot="content">
        <span style="display: inline-block;margin: 10px 0;padding: 10px 15px ;background-color:#F19A0D;color: #fff;border-radius: 10px;font-size: 14px;" >{{ detail.tempName }} </span>
        <div class="price">
            <div class="now-price">
              <span class="unit">¥ </span>
              <span class="num">{{ detail.storeInfo.price }}</span>
            </div>
            <div class="old-price">
              <span>原价:{{ Math.trunc(detail.storeInfo.otPrice) }}</span>
            </div>
          </div>
        <div style="text-align: right;">
          <span  class="status-btn" style="">立即购买</span>
          </div>
      </div>
    </main-info>
    <!-- 选项卡 -->
    <category isDetail @change="changeCategory" :categorys="categorys"></category>
    <txt-view v-if="categoryIndex === 0" class="txt" :txt="detail.storeInfo.description"></txt-view>
  
    <comment v-else :id="$route.params.id" :contentType="$route.meta.contentType" />
    <!-- <recommend url="activity" :id="$route.params.id"   :contentType="$route.meta.contentType"/> -->
  </div>
</template>

<script>
import {
  getDetail
} from "@/api/buy/buy";
// import { getDetail,getDict} from "@/api/order/order";
// 主信息插槽
import MainInfo from "@/components/MainInfo";
import DictTag from '@/components/DictTag'
import Recommend from '@/components/RelatedRecommend'
import TMmap from "@/components/TMmap"
  export default {
    
    name:'projectDetail',
    components: {
      MainInfo,DictTag,Recommend,TMmap
    },
    watch: {
     $route(){
      this.getData()

     } 
    },
    data() {
      return {
        list: {},
	       mapShow:false ,
        detail:null,
        dict:{},  
        categoryIndex: 0,//分类下标
        categorys: [{
                name: '产品详情',
                newsType: 1
            },{
                name: '评论',
                newsType: 2
            }],
      }
    },
    created () {
      this.getData()
      // this.getDictList()
    },
    methods: {
      changeCategory(index) {
            this.categoryIndex = index
            // this.getList()
        },
      getData() {
        getDetail( this.$route.params.id).then(res=>{
          this.detail=res.data
        })
      },
    },

  }
</script>

<style lang="scss" scoped>
.container {
  width: 1200px;
    margin: 0 auto;
    .status-btn {
      display: inline-block;padding: 10px 20px;background-color:#E85984;color: #fff;border-radius: 10px;
    }
    .price {
        display: flex;
        align-items: center;
        .now-price {
          color: #E9487A;
          .num {
            font-size: 24px;
            font-weight: bold;
          }

        }
        .old-price {
          margin-left: 10px;
          color: #999;
        }
      }

}
</style>